export let config = {
  scrollSpeed: 800,

  home: {
    titleSpeed: 0.02,
    delay: 0,
  },

  aboutUs: {
    titleSpeed: 0.02,
    delay: 0,
  },

  services: {
    titleSpeed: 0.02,
    delay: 0,
  },

  projects: {
    titleSpeed: 0.02,
    delay: 0,
  },

  contact: {
    titleSpeed: 0.02,
    delay: 0,
  },
  blogs: {
    titleSpeed: 0.02,
    delay: 0,
  },
  careers: {
    titleSpeed: 0.02,
    delay: 0,
  },

  socialLinks: {
    facebook: "https://www.facebook.com/ozorix/",
    linkedin: "https://www.linkedin.com/company/ozorix-inc-/",
  },

  blogData: [
    {
      title: "No content just yet",
      text: ""
    }
  ],

  careersData: [
    {
      id: "ux-designer-react",
      title: "UX Designer react",
      list: `<li>5 year experience +</li>
             <li>Native English</li>
             <li>Software: Sketch, Figma</li>
             <li>adobe Xd</li>`,
    },
    {
      id: "ux-designer-angular",
      title: "UX Designer angular",
      list: `<li>5 year experience +</li>
             <li>Native English</li>
             <li>Software: Sketch, Figma</li>`,
    },
    {
      id: "ux-designer",
      title: "UX Designer ",
      list: `<li>5 year experience +</li>
             <li>Native English</li>
             <li>Software: Sketch, Figma</li>`,
    },
  ],

  aboutUsTextData: `
     We are Ozorix, a Digital agency specializing in complex IT solutions.
    Our goal is to create, effective business solutions customized to clients needs.
    <br /> <br /> <br />

    Our team has 20 years of experience in building complex digital  systems for businesses of every size and industry. They are highly  qualified maximalists, and enthusiasts.
    <br /> <br /> <br />
    The 21st century is an inexhaustible source of innovative opportunities that enable us to spread our wings on a larger scale and achieve the  best results. `,

  aboutUsValues: [
    "Think big",
    "Earn Trust",
    "Tackle problems",
    "Be Agile",
    "Help each other",
    "Be Efficient",
    "Never stop",
    "Take responsibility",
  ],

  contactTextData: {
    mobile: "Mobile",
    mobileInit: "+(995) 599 923 601",
    mobileHref: "tel:+995599923601",
    email: "E-mail",
    emailInit: "info@ozorix.com",
    emailHref: "mailto:info@ozorix.com",
    address: "Address",
    addressInit: "14b Shalva Nutsubidze St",
  },

  customersTextData: {
    "customers-1-1": "./assets/customers/data-science.png",
    "customers-1-1Href": "#",
    "customers-1-2": "./assets/customers/blockchain.png",
    "customers-1-2Href": "#",
    "customers-1-3": "./assets/customers/quality-assurance.png",
    "customers-1-3Href": "#",
    "customers-2-1": "./assets/customers/communication-tool.png",
    "customers-2-1Href": "#",
    "customers-2-2": "./assets/customers/gis.png",
    "customers-2-2Href": "#",
    "customers-2-3": "./assets/customers/friend.png",
    "customers-2-3Href": "#",
    "customers-3-1": "./assets/customers/internet-of-things.png",
    "customers-3-1Href": "#",
    "customers-3-2": "./assets/customers/blockchain.png",
    "customers-3-2Href": "#",
    "customers-3-3": "./assets/customers/blockchain.png",
    "customers-3-3Href": "#",
    "customers-4-1": "./assets/customers/blockchain.png",
    "customers-4-1Href": "#",
  },

  ProjectTextData: [
    {
      index: "./assets/customers/data-science.png",
      href: "https://www.facebook.com",
      name: "CityCom IoT (Georgia)",
      description: "Building automation and security system",
      tech: "Arduino/RaspberryPi/RFID, AWS IoT, NodeJS, MySQL",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "CityCom Big Data (Georgia)",
      description: "Business intelligence and data warehousing",
      tech: "AWS Kinesis, Apache Spark, Apache Hive",
    },
    {
      index: "./assets/customers/quality-assurance.png",
      href: "https://www.facebook.com",
      name: "TrackStat (USA) ",
      description:
        "Electronic patient record management, CRM, messaging, Scheduling and business intelligence.",
      tech: "AWS Serverless API, NodeJS, .NEt/C#, MS SQL Server, Angular",
    },
    {
      index: "./assets/customers/communication-tool.png",
      href: "https://www.facebook.com",
      name: "MyGeorgia (Georgia)",
      description: "GIS System",
      tech: "Geoserver, OpenLayers, PostGIS, MapBox Vector Tiles, AWS",
    },
    {
      index: "./assets/customers/gis.png",
      href: "https://www.facebook.com",
      name: "CloserConnect (USA) ",
      description: "",
      tech: "AWS, MongoDB, NodeJS, ExpressJS, Angular",
    },
    {
      index: "./assets/customers/friend.png",
      href: "https://www.facebook.com",
      name: "Wurth (Germany)",
      description: "",
      tech: ".NET/C#, ThreeJS",
    },
    {
      index: "./assets/customers/internet-of-things.png",
      href: "https://www.facebook.com",
      name: "Siemens SpecWriter Online (USA)",
      description:
        "Online tool to assist engineers in the process of building a fire safety and building automation systems",
      tech: "AWS, MongoDB, NodeJS, ExpressJS, Angular",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "Siemens System Builder (USA)",
      description: `Online tool to assist engineers in the process of building
      a fire safety and building automation
      systems equipment list and associated basic power  calculations
      for estimating and quoting new projects.`,
      tech: "AWS, MongoDB, NodeJS,ExpressJS, Angular",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "rek10 (USA)",
      description: "Feedback/Case management system",
      tech: "AWS, NodeJS, React",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "Trek10 KAR EDMM (USA)",
      description: "Medical imaging storage and  processing system",
      tech: "AWS S3, AS Lambda, DynamoDB",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "DocuNote (Denmark)",
      description: "Document management system",
      tech: ".NET, MS SQL Server",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "DocuLedge (Georgia)",
      description:
        "Document verification application based on Blockchain smartcontracts technologies",
      tech: "IBM Hyperledger, Node.js, AWS S3",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "BivLab GPS (Thailand)",
      description:
        "GPS Tracking system to collect data from vehicles and display on GIS dashboard",
      tech: "MapBox, OpenLayers, AWS IoT, Kinesis, DynamoDB, Grafana",
    },
    {
      index: "./assets/customers/blockchain.png",
      href: "https://www.facebook.com",
      name: "BivLab IoT (Thailand)",
      description: `Collect data from IoT sensors on natural gas and oil
      production sites, run collected data through physical
      models and provide feedback to remote devices to
      adjust their operational parameters.`,
      tech: "AWS IoT, Kinesis, MQTT, AWS Lambda, C++, Matlab",
    },
  ],

  servicesTextData: {
    "services-1-1__title": "Data Science",
    service_logo1: "./assets/customers/data-science.png",
    "services-1-1__ul": `
      <li> Data analytics; </li>
      <li> Business intelligence; </li>
      <li> Machine learning; </li>
      <li> Deep learning. </li>`,

    "services-1-2__title": "AWS Solutions",
    service_logo2: "./assets/customers/cloud-service.png",
    "services-1-2__ul": `
      <li> AWS Migration; </li>
      <li> Cloud architecture design; </li>
      <li> Serverless solutions. </li>`,

    "services-1-3__title": "Quality Assurance",
    service_logo3: "./assets/customers/quality-assurance.png",
    "services-1-3__ul": `
      <li> Quality Control Consulting; </li>
      <li> Functional, Performance,
      <br> Load/Stress Testing. </li>`,

    "services-2-1__title": "Enterprise Software <br> </b> Development",
    service_logo4: "./assets/customers/communication-tool.png",
    "services-2-1__ul": `
      <li>Design and development web
      <br> based/mobile business solutions;</li>
      </br>  <li>On platforms: NET, NodeJS,
      Java/Scala/Grails.</li>`,

    "services-2-2__title": "GIS Systems",
    service_logo5: "./assets/customers/gis.png",

    "services-2-2__ul": `
      <li style="margin-top:55px;">Design and development of GIS
      <br> backend and frontend solutions;</li>
      <li>On platforms: OpenLayers, Leaflet.</li>`,

    "services-2-3__title": "Database Services",
    service_logo6: "./assets/customers/friend.png",

    "services-2-3__ul": `
      <li style="margin-top:55px;">Data warehousing;</li>
      <li>Big data processing.</span></li>`,

    "services-3-1__title": "Teach Leading and <br> Consulting",
    service_logo7: "./assets/customers/internet-of-things.png",

    "services-3-1__ul": `
      <li>Consulting;</li>
      <li>Architecture design;</li>
      <li>Audit existing technical solutions;</li>
      <li>Mentoring.</li>`,

    "services-3-2__title": "IoT Solutions",
    service_logo8: "./assets/customers/blockchain.png",
    "services-3-2__title1": "Blockchain Solutions",
  },
};
